import axios from "axios";
import React, { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import "react-toastify/dist/ReactToastify.css";
import Lottie from "lottie-react";
import { API } from "../App";
import Auth0Icon from "../assets/auth0.svg";
import TableauIcon from "../assets/tableau_icon.svg";
import LottieBluePulse from "../assets/lottie/blue-pulse.json";
import { useAuth0 } from "@auth0/auth0-react";
import UAParser from "ua-parser-js";
import NonDesktopUserNotice from "./NonDesktopUserNotice";
import isSandboxEnvironment from "../utils/is-sandbox-environment";

const userAgent = new UAParser();
const deviceType = userAgent.getDevice().type;
const isDesktop =
  deviceType === undefined ||
  !["console", "mobile", "tablet", "smarttv", "wearable", "embedded"].includes(
    deviceType
  );

function Login() {
  const [navbarToggle, setNavbarToggle] = useState(false);
  const [token, setToken] = useState("");
  // Auth0 Login
  const { loginWithRedirect } = useAuth0();

  const defaultButtonRef = useRef(null);

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent the default form submission
      defaultButtonRef.current.click(); // Programmatically click the default button
    }
  };

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const notify = (data) => toast.error(data);
  const successNotify = (data) => toast.success(data);
  const [isPassVisible, setIsPassVisible] = useState(false);
  //react hook form Initialization
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  // Logic for On Submit Login Button
  const onSubmit = async (data) => {
    setLoading(true);
    try {
      let header = {};
      if (typeof token == "string" && token.length > 0) {
        header["Authorization"] = `Bearer ${token}`;
      }
      const response = await axios.post(
        `${API}/auth/`,
        {
          user: data.email,
          pwd: data.password,
        },
        {
          withCredentials: true,
        }
      );
      console.log(response.status);
      if (response.status === 200) {
        sessionStorage.setItem("isLogged", true);
        sessionStorage.setItem("Username", data.email);
        successNotify("Logged In Successfully");
        navigate("/app");
        window.location.reload();
      }
    } catch (err) {
      //console.log(err.response.data.error);
      notify(err.response.data.error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };
  return isDesktop ? (
    <div className="h-[100vh] flex flex-row w-[100vw]">
      {/* Sandbox banner */}
      <div
        className={`${
          isSandboxEnvironment() ? "block" : "hidden"
        } z-10 fixed top-0 left-0 w-full bg-[#ff0000] text-white text-center h-[27px]`}
      >
        🏗️ This is a sandboxed instance
      </div>
      {/* loading Component */}
      {loading && (
        <Spin
          className="spinning_indicator"
          indicator={
            <LoadingOutlined
              style={{
                fontSize: 24,
              }}
              spin
            />
          }
        />
      )}

      {/* <div className="flex items-center flex-row justify-center h-full w-full bg-gradient-to-b from-[#001A33] to-[#00404C] via-[#00468A] animate-gradient-y"> */}
      <div className="flex items-center flex-row justify-center h-full w-full bg-[#001A33]">
        <Lottie
          animationData={LottieBluePulse}
          loop
          className="absolute w-[80vw]"
        />
        <div className="flex flex-col items-center">
          <div className="relative rounded-[6px] bg-[#ffffff] gap-2 w-[450px] flex flex-col p-8 border-solid border-[1px] border-light-blue-500 mr-[60px]">
            <div className="text-[#001a33] font-[500] text-start ">
              <div className="font-medium text-[16px]">
                Please sign in with your
              </div>
              <div className="font-bold text-lg">
                Single Sign On (SSO) account
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col mt-2 gap-2 justify-around"
              onKeyDown={handleKeyPress}
            >
              {/* Auth0 login button */}
              <div
                className="w-[100%] text-[1rem] gap-4  justify-between items-center p-4 bg-[#203660] hover:bg-[#2d497d] focus:bg-slate-50 font-[400] rounded-md cursor-pointer flex flex-row-reverse text-[#D9E6F2] mb-4"
                onClick={() => loginWithRedirect()}
              >
                <img
                  src={Auth0Icon}
                  alt=""
                  style={{ mixBlendMode: "overlay" }}
                />
                <button className="">
                  Sign in using{" "}
                  <span className="font-[500]">SSO</span>
                </button>
              </div>
              {/* "–––––– OR ––––––" */}
              <div className="w-full h-10 flex flex-row justify-center items-center">
                <div className="flex flex-row w-[100%] justify-center items-center">
                  <div className="basis-[43%] h-[1px] bg-[#03111b29]"></div>
                  <div className="basis-[13%] text-[#6f7c89] font-medium text-[14px] flex flex-row justify-center items-center">
                    OR
                  </div>
                  <div className="basis-[43%] h-[1px] bg-[#03111b29]"></div>
                </div>
              </div>
              {/* Email and Password fields */}
              <div className="flex flex-col mt-2 gap-4 justify-around">
                <div className="text-lg text-[#001a33] font-[500] text-start ">
                  <div className="text-lg">
                    If you have TableauID
                  </div>
                </div>
                <div className="flex flex-col text-[1rem]">
                  <input
                    placeholder="Email Address"
                    {...register("email", { required: true })}
                    className={`${
                      errors.email
                        ? "error_outline"
                        : "no_outline border-[1px] border-[#c2cad2]"
                    } p-3 rounded-t-md border-[1px] border-[#c2cad2] focus:outline-[#00369E] cursor-pointer bg-[#f0f2f4] hover:bg-slate-50 focus:bg-slate-50 relative z-0 focus:z-[1]`}
                  />
                  {/* Password Input Field */}
                  <input
                    type={isPassVisible ? "text" : "password"}
                    placeholder="Password"
                    {...register("password", { required: true })}
                    className={`${
                      errors.password
                        ? "error_outline"
                        : "no_outline border-[1px] "
                    } p-3 relative top-[-1px] w-auto rounded-b-md border-[1px] border-[#c2cad2] focus:outline-[#00369E] cursor-pointer bg-[#f0f2f4] hover:bg-slate-50 focus:bg-slate-50 relative z-0 focus:z-[1]`}
                  />
                  {errors.email && (
                    <span className="text-[#808C99]">
                      Please provide an email address
                    </span>
                  )}
                  {errors.password && (
                    <span className="text-[#808C99]">
                      Please enter your password
                    </span>
                  )}
                </div>
              </div>

              {/* Button Group */}
              <div className="flex flex-col mt-4 gap-2 justify-around">
                {/* Tableau login button */}
                <div
                  className="w-[100%] text-[1rem] gap-4  justify-between items-center p-4 bg-[#001A33] hover:bg-[#05396b] font-[400] rounded-md cursor-pointer flex flex-row-reverse text-[#D9E6F2]"
                  onClick={handleSubmit(onSubmit)}
                >
                  <img
                    src={TableauIcon}
                    alt=""
                    style={{ mixBlendMode: "luminosity" }}
                  />
                  <button className="" ref={defaultButtonRef}>
                  Sign in using{" "}
                  <span className="font-[500]">TableauID</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
          {/* Additional support */}
          <div className="relative w-full h-10 mr-[60px] flex flex-row justify-center items-center mt-2">
            <div className="text-[#6f7c89] font-normal text-[14px] text-center">
              To request access or support, please{" "}
              <a
                href="https://www.theqadimgroup.com/contact-us"
                className="text-blue-500 underline"
              >
                contact us
              </a>
              .
            </div>
          </div>
        </div>
        <div className="relative w-[325px] mt-8 items-center flex flex-row justify-center">
          <img src="logo-378px.png" alt="" className="h-[auto]" />
        </div>
      </div>

      <div
        className={`w-[100%] text-[12px] absolute right-[-60px] bottom-2 cursor-pointer`}
      >
        <div className="w-[100%] flex justify-center">
          <p className="w-[520px] mt-4 mb-4 text-[12px] text-[#6378a0] text-center">
            This platform solution is the property of The Qadim Group, Inc.
            ("TQG"), and the use of it is governed by the agreement between your
            organization and TQG. No part of it may be shown outside of your
            organization, including with any other software or consulting firm,
            without checking with TQG.
          </p>
        </div>
        <div className="flex-row justify-center items-center gap-4 flex ">
          <a
            href="https://prod-ca-a.online.tableau.com/#/site/qadencebytqg/explore"
            target="_blank"
          >
            <p className="text-[#6378a0] hover:text-[#668ed7] font-[500]">
              Tableau Cloud
            </p>
          </a>
          <a href="http://theqadimgroup.com/privacy-policy" target="_blank">
            <p className="text-[#6378a0] hover:text-[#668ed7] font-[500]">
              Privacy Policy
            </p>
          </a>
          <a href="http://theqadimgroup.com/privacy-policy" target="_blank">
            <p className="text-[#6378a0] hover:text-[#668ed7] font-[500]">
              Terms of Use
            </p>
          </a>
        </div>
      </div>

      <ToastContainer position="top-right" closeOnClick autoClose={false} />
    </div>
  ) : (
    <NonDesktopUserNotice />
  );
}

export default Login;
