import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import axios from "axios";
import { API } from "../App";
import QadenceImage from "../assets/quandence_home.svg";
import SideNavToggle from "../assets/closeNav.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import vipList from "../utils/vip-list";

function Perspective() {
  const [navbarToggle, setNavbarToggle] = useState(true);
  const [dashboardView, setDashboardView] = useState(false);
  const [dashboardLink, setDashboardLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [workbooks, setWorkBooks] = useState([]);
  const [isEmptyWorkbooks, setIsEmptyWorkbooks] = useState(false);
  const username = sessionStorage.getItem("Username");
  const email = username;
  const emailDomain = email.split("@").pop();
  const isVIP = vipList.includes(emailDomain);

  // Set Clicked Dashboard Link
  const setDashboardLinkData = (link) => {
    setDashboardLink(link);
  };

  // To switch to Dashboard View
  const setDashboardViewStatus = (status) => {
    setDashboardView(status);
  };

  useEffect(() => {
    // Checking in localstorage whether the user is logged in
    if (sessionStorage.getItem("isLogged")) {
      console.log('fetching tableau views');
      fetchAllData();
    }
  }, []);

  useEffect(() => {}, [dashboardLink]);
  // Executes when the Home page loads fetches allviews and allworkbooks
  const fetchAllData = async () => {
    setLoading(true);
    // Fetches All Views direspective of workbooks
    let allViews = [];
    let allWorkBooks = [];
    try {
      allViews = await axios.get(`${API}/tableau/views`, {
        withCredentials: true,
      });
      allWorkBooks = await axios.get(`${API}/tableau/workbooks`, {
        withCredentials: true,
      });
    } catch (error) {
      console.error(`unable to fetch views or workbooks, ${error}`);
    }
    // Get All Workbooks for uses

    if (allWorkBooks.data?.workbooks.length > 0) {
      // merging workbooks and Views responses Together
      allWorkBooks?.data?.workbooks?.map((workbook) => {
        allViews.data.views.map((item) => {
          if (workbook.name === item.name) {
            if (workbook?.views?.length > 0) {
              workbook.views = [...workbook.views, item];
            } else {
              workbook.views = [item];
            }
          }
        });
      });

      // Grouping Workbooks by projects
      async function groupByProject(arr) {
        const grouped = {};
        arr.forEach((item) => {
          if (!grouped[item.project.name]) {
            grouped[item.project.name] = {
              workbooks: [],
              project: item.project.name,
            };
          }
          grouped[item.project.name].workbooks.push(item);
        });

        return Object.values(grouped);
      }
      const res = await groupByProject(allWorkBooks.data.workbooks);

      // Sort Projects Alpahbetical Order
      res.sort((a, b) => {
        return a.project.localeCompare(b.project);
      });

      // Sort Projects Workbooks Alphabetical Order
      res.map((item) => {
        item.workbooks.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });
      });
      setWorkBooks((workbooks) => [...workbooks, ...res]);
    } else {
      // if no Workbooks is there for user
      setIsEmptyWorkbooks(true);
    }
    setLoading(false);
  };

  return (
    <div className="h-[100vh] flex flex-row w-[100vw]">
      <Sidebar
        data={workbooks}
        setDashboardViewStatus={setDashboardViewStatus}
        setDashboardLinkData={setDashboardLinkData}
        link={dashboardLink}
        isNavbarOpen={navbarToggle}
        isEmptyWorkbooks={isEmptyWorkbooks}
      />
      {!dashboardView && (
        <div
          className="flex flex-col  w-full justify-center items-center p-8 gap-4 h-[100vh] overflow-hidden"
          style={{
            background: 'url("noise-background.png") #ffffff',
          }}
        >
          <div className="flex flex-row gap-8 w-[100%] justify-center">
            {!isVIP ? (
              <img
                src={QadenceImage}
                alt=""
                className="saturate-[.3] opacity-[0.2]"
              />
            ) : (
              <div
                className="w-[100%] h-[100px] overflow-hidden saturate-[.3] opacity-[0.2]"
                style={{
                  background: `url("client-logos/${emailDomain}(large).png") center center no-repeat`,
                }}
              ></div>
            )}
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100vw",
                  height: "100vh",
                }}
              >
                <Spin
                  className="spinning_indicator"
                  spinning={loading}
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 50,
                      }}
                      spin
                    />
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}
      {dashboardView && dashboardLink && <Dashboard link={dashboardLink} />}
      <div
        className={`absolute hover:opacity-90 bg-[#0b376d] bottom-[27px] cursor-pointer z-[10000000] shadow-md transition-all ease-in-out duration-200 transform-gpu ${
          navbarToggle ? "scale-x-[1] opacity-100 left-[450px] rounded-r-sm" : "scale-x-[-1] opacity-50 left-[0px] rounded-l-sm"
        }`}
        onClick={() => setNavbarToggle(!navbarToggle)}
      >
        <img src={SideNavToggle} alt="" />
      </div>
    </div>
  );
}

export default Perspective;

// Dashboard Page
function Dashboard({ link }) {
  let token = null;
  // tableau host and tableau content url initialization
  const TABLEAU_HOST = "https://prod-ca-a.online.tableau.com";
  const TABLEAU_CONTENT_URL = "qadencebytqg";
  useEffect(() => {
    // extracting the view name from the url
    const parsedUrl = link
      .split("/")
      .filter((_, i) => i !== 1)
      .join("/");
    // Calls loadTableau Function
    loadTableau(parsedUrl);
  }, [link]);

  async function getToken() {
    if (token == null) {
      const res = await axios.get(`${API}/tableau/token`, {
        withCredentials: true,
      });
      if (res != null) {
        if (res.status === 200) {
          token = res.data.token;
          setTimeout(() => (token = null), 6e5);
        }
      }
    }
    return token;
  }

  // laoding dashboard tableau-viz element
  async function loadTableau(dash) {
    const token = await getToken();
    if (token != null) {
      const tp = `
        <tableau-viz id="tableauViz" src=${TABLEAU_HOST}/t/${TABLEAU_CONTENT_URL}/views/${dash} 
          onFirstInteractive="onTabFirstInteractiveHandler"
            token=${token}
          height="100vh"
          width="100%"
          toolbar="bottom">
        </tableau-viz>`;
      document.getElementById("tableau").innerHTML = tp;
    }
  }
  return (
    <div className="h-[100vh] flex flex-row flex-auto w-full">
      <div id="tableau" className="w-full h-[100vh] bg-zinc-300"></div>
    </div>
  );
}
