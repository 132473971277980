import React, { Component } from "react";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import TableauIcon from "../assets/tableau_icon.svg";
import isSandboxEnvironment from "../utils/is-sandbox-environment";

class NonDesktopUserNotice extends Component {
  openAppOrFallback(appUrl, fallbackUrl) {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = appUrl;
    document.body.appendChild(iframe);

    window.setTimeout(() => {
      document.body.removeChild(iframe);
      window.location.href = fallbackUrl;
    }, 2000);
  }

  render() {
    return (
      <div className="flex items-center justify-between h-[100vh] flex flex-col w-[100vw] bg-gradient-to-b from-[#001A33] to-[#00404C] via-[#00468A] animate-gradient-y pt-4">
        <div className="w-[250px] mt-8 items-center flex flex-row justify-center">
          <img src="logo-378px.png" alt="" className="h-[auto]" />
        </div>
        
        {/* Sandbox banner */}
        <div
          className={`${
            isSandboxEnvironment() ? "block" : "hidden"
          } z-10 fixed top-0 left-0 w-full bg-[#ff0000] text-white text-center h-[27px]`}
        >
          🏗️ This is a sandboxed instance
        </div>

        <div className="flex items-center flex-col absolute top-[25%]">
          <p className="mt-2 text-[#ffffff] font-[500] w-[300px] text-center opacity-75">
            Qadence is best viewed on desktop. We invite you to consider the
            options below, or to visit again at a later date.
          </p>
          <div className="flex gap-12 pt-6 pb-4">
            <div className="scale-[1.8]">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.openAppOrFallback(
                    "tableau://prod-ca-a.online.tableau.com/#/site/qadencebytqg/explore",
                    "https://play.google.com/store/apps/details?id=com.Tableau.TableauApp"
                  );
                }}
              >
                <AndroidIcon />
              </a>
            </div>
            <div className="scale-[1.8]">
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  this.openAppOrFallback(
                    "tableau://prod-ca-a.online.tableau.com/#/site/qadencebytqg/explore",
                    "https://apps.apple.com/us/app/tableau-mobile/id434633927"
                  );
                }}
              >
                <AppleIcon />
              </a>
            </div>
          </div>
          <a
            href="mailto:?subject=Qadence%20by%20TQG%20Desktop%20Experience&body=Visit%20Qadence%20by%20TQG%20on%20desktop:%20https://www.qadencebytqg.com/"
            target="_blank"
            rel="noreferrer"
            className="mt-2"
          >
            <button className="px-6 text-center text-sm gap-2 flex items-center text-[#4D8AFF]">
              Send URL to your inbox
            </button>
          </a>
        </div>
        <div className="bg-[#091F49] p-4 pb-8 w-[100%] text-[14px] fixed bottom-[0px] rounded-t-2xl flex flex-col items-center">
          <div
            style={{
              display: "inline-block",
              marginBottom: 20,
              width: 70,
              height: 4,
              backgroundColor: "#ffffff",
              borderRadius: 1000,
              opacity: 0.2,
            }}
          ></div>
          <a
            href="https://www.theqadimgroup.com/r/LA0"
            target="_blank"
            rel="noreferrer"
            className="mt-4"
          >
            <button className="bg-[#1a4698] px-6 py-2 rounded-[30px] text-[#ffffff] text-center text-sm gap-2 flex items-center">
              <MarkEmailUnreadIcon />
              Connect with the Qadence Team
            </button>
          </a>
        </div>
      </div>
    );
  }
}

// Include the export statement if your module system requires it.
export default NonDesktopUserNotice;
