import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import isSandboxEnvironment from './utils/is-sandbox-environment';

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = isSandboxEnvironment() ? 'tqg.us.auth0.com' : process.env.REACT_APP_AUTH0_DOMAIN;
  const endpoint = isSandboxEnvironment() ? 'tqg.us.auth0.com' : 'tqg-prod-one.us.auth0.com';
  const clientId = isSandboxEnvironment() ? 'qbbrPyXEGGDBqkIUTFcSsFNRslY6T7lN' : process.env.REACT_APP_AUTH0_CLIENT_ID;


  if (!(domain && clientId)) {
    return null;
  }


  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri:`${window.location.origin + '/'}`,
        audience: `https://${endpoint}/api/v2/`,
        scope: 'profile email read:users'
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;